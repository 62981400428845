import moment from "moment";
import phoneFilter from "@/helpers/filters/phoneFilter";

const tableColsAccess = [
  {
    text: "ID доступа",
    value: "id",
    sort: true,
    width: "70"
  },
  {
    text: "ФИО",
    value: "full_name",
    renderFunction: value => value?.user_details?.full_name
  },
  {
    text: "Телефон",
    renderFunction: value =>
      phoneFilter.execute(value?.user_details?.phone_number),
    userRole: [10, 20, 21, 30, 31, 40, 50, 60, 70]
  },
  {
    text: "ID Пользователя",
    value: "user",
    sort: true,
    width: "70"
  },
  {
    text: "Дата создания",
    renderFunction: value =>
      `${moment(value.date_start * 1000).format("DD.MM.YYYY HH:mm")}`
  },
  {
    text: "Дата окончания",
    // value: "created_at"
    renderFunction: value =>
      `${moment(value.date_end * 1000).format("DD.MM.YYYY HH:mm")}`
  },
  {
    text: "Статус",
    value: "is_active",
    renderFunction: value =>
      `<span style="color: ${value.is_active ? "#95C23D" : "#EB5C6D"}">${
        value.is_active
          ? "Включен"
          : `Выключен (${value.inactive_reason_translate})`
      }</span>`,

    userRole: [10, 20, 30, 40, 50, 60, 70],
    sort: true
  },
  {
    text: "Тип доступа",
    value: "access_type",
    renderFunction: value => {
      return value.access_type === "app"
        ? "Приложение"
        : value.access_type === "card" && value.card_details != null
        ? value?.card_details?.code
        : "-";
    },
    sort: true
  },
  {
    text: "Вид доступа",
    renderFunction: value =>
      value.access_by == "unpaid"
        ? "Неоплачиваемый доступ"
        : value.access_by == "trial"
        ? "Бесплатный период"
        : value.access_by == "guest"
        ? "Гостевой доступ"
        : value.access_by == "paid"
        ? "Оплаченный доступ"
        : value.access_by == "activated"
        ? "Активированный доступ"
        : "",
    value: "access_by",
    sort: true
  },

  {
    text: "Оплата",
    renderFunction: value => value.payment,
    width: 25,
    value: "payment",
    sort: true
  },

  {
    text: "Период, мес",
    renderFunction: value => value.duration,
    value: "duration",
    sort: true
  },

  {
    text: "Группа пользователей",
    value: "group",
    renderFunction: value => (value.group_title ? value.group_title : ""),
    sort: true
  },

  {
    text: "Название объекта",
    value: "card_details.organization_name",
    renderFunction: value => {
      return `${value.object_details.name}`;
    }
  }
];

export default tableColsAccess;
