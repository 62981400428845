const slideTabsUsers = [
  {
    text: "Общая информация",
    value: "info",
    icon: "i",
    role: [10, 20, 21, 30, 31, 40, 50, 60, 70]
  },
  {
    text: "Объекты пользователя",
    value: "objects",
    icon: null,
    count: this?.sourceObject?.results?.objects_count,
    role: [10, 30, 40, 50, 60, 70, 110, 120]
  },
  {
    text: "Платежи",
    value: "payments",
    icon: null,
    role: [10, 40, 50, 60, 70, 110, 120]
  },
  {
    text: "Журнал проходов",
    value: "pass_log",
    icon: null,
    count: null,
    role: [10, 40, 50, 60, 70, 110, 120]
  },
  {
    text: "Доступы",
    value: "access",
    icon: null,
    count: null,
    role: [10, 40, 50, 60, 70, 110, 120]
  }
];

export default slideTabsUsers;
