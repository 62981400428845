import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import moment from "moment";
import phoneFilter from "@/helpers/filters/phoneFilter";

const tableColsPayment = [
  {
    text: "ID",
    value: "id",
    userRole: [30, 40, 50, 60, 70],
    width: 25,
    sort: true
  },
  {
    text: "Статус платежа",
    value: "status_display",
    userRole: [10, 20],
    renderFunction: value =>
      value.status == "awa-card"
        ? "Завершен"
        : value.status == "refunded"
        ? "Возвращен"
        : value.status == "reversed"
        ? "Возвращен"
        : value.status_display
  },
  {
    text: "Статус платежа",
    value: "status_display",
    userRole: [30, 40, 50, 60, 70],
    renderFunction: value =>
      value.status == "awa-card"
        ? "Завершен"
        : value.status == "refunded"
        ? `Возвращен </br> ${
            moment(value.refunded_at * 1000).isValid() &&
            value.refunded_at != null
              ? moment(value.refunded_at * 1000).format("DD.MM.YYYY HH:mm")
              : "-"
          }`
        : value.status_display
  },
  {
    text: "Дата и время платежа",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    value: "created_at",
    renderFunction: value =>
      `${
        moment(value.created_at * 1000).isValid()
          ? moment(value.created_at * 1000).format("DD.MM.YYYY HH:mm")
          : "-"
      }`,
    sort: true
  },

  {
    text: "Сумма оплаты",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    value: "amount",
    renderFunction: value => `${value.amount || "-"} ₽`,
    sort: true
  },
  {
    text: "Промокод",
    userRole: [30, 40, 50, 60, 70],
    value: "coupon",
    renderFunction: value =>
      `${value.coupon?.code ? `${value.coupon?.code + ","}` : ""} ${
        value.coupon?.discount_percent
          ? `${value.coupon?.discount_percent + "%"}`
          : "-"
      }`,
    src: "/img/table_icons/promocodes.svg",
    width: 20
  },

  {
    text: "Оплачено до",
    value: "date_end",
    renderFunction: value =>
      value.status == "awa-card"
        ? value.status_display
        : `${
            value.date_end && moment(value.date_end * 1000).isValid()
              ? moment(value.date_end * 1000).format("DD.MM.YYYY HH:mm")
              : "-"
          }`,
    userRole: [10, 20, 30, 40, 50, 60, 70]
  },
  {
    text: "ФИО",
    userRole: [70],
    value: "user_details",
    renderFunction: value => {
      return value.user_details?.full_name;
    }
  },
  // {
  //   text: "Пользователь",
  //   userRole: [10, 20, 21, 30, 31, 40, 50, 60, 70],
  //   renderFunction: value => {
  //     return value?.additional_fields.length != 0
  //       ? value?.additional_fields[0].value
  //       : "-";
  //   },
  //   value: "user"
  // },
  // {
  //   text: "Комментарий",
  //   userRole: [10, 20, 21, 30, 31, 40, 50, 60, 70],
  //   renderFunction: value => {
  //     return value?.additional_fields.length != 0
  //       ? value?.additional_fields[1].value
  //       : "-";
  //   },
  //   value: "comment"
  // },
  {
    text: "Телефон",
    userRole: [30, 40, 50, 60, 70],
    renderFunction: value => {
      return phoneFilter.execute(value.user_details?.phone_number);
    }
  },
  {
    text: "Тип доступа",
    value: "price_type",
    renderFunction: value => {
      return value.price_type === "app"
        ? "Приложение"
        : value.price_type === "card"
        ? "Карта"
        : "Ошибка";
    },
    userRole: [10, 20, 30, 40, 50, 60, 70]
  },

  {
    text: "Срок действия",
    // value: "accsess_type",
    renderFunction: value => {
      return value.access_duration ? value.access_duration : "-";
    },
    userRole: [10, 20, 30, 40, 50, 60, 70]
  },
  {
    text: "Осталось дней",

    userRole: [30, 40, 50, 60, 70],
    renderFunction: value => {
      return value.days_left && value.days_left > 0 ? value.days_left : "0";
    }
  },
  {
    text: "Эквайринг",
    userRole: [30, 40, 50, 60, 70],
    renderFunction: value => {
      return value.is_sbp
        ? "СБП"
        : dictionariesHelper.acquiringProvider[value.acquiring_provider];
    }
  },

  {
    text: "Получатель платежа",
    userRole: [30, 40, 50, 60, 70],
    // value: "amount"
    renderFunction: value =>
      dictionariesHelper.acquiringEntityPayment[value.acquiring_entity_type]
  },
  {
    text: "Группа пользователей",
    userRole: [30, 40, 50, 60, 70],
    renderFunction: value => value.group?.title || "-",
    sort: true,
    src: "/img/table_icons/tabbar__icon_8.svg",
    width: 20
  },
  {
    text: "Тип объекта",
    userRole: [30, 40, 50, 60, 70],
    value: "objectType",
    renderFunction: value =>
      dictionariesHelper.objectType[value.object?.type] || "-"
  },
  {
    text: "Объект",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    value: "object",
    renderFunction: value => {
      return value.object?.name;
    },
    src: "/img/table_icons/tabbar__icon_3.svg",
    width: 20,
    sort: true
  },
  {
    userRole: [60, 70],
    text: "Компания",
    value: "organization",
    renderFunction: value => {
      return value.organization?.name;
    },
    src: "/img/table_icons/tabbar__icon_2.svg",
    width: 20,
    sort: true
  },
  {
    userRole: [70],
    text: "Дилер",
    value: "diller_name",
    src: "/img/table_icons/tabbar__icon_1.svg",
    width: 20,
    sort: true
  }
];

export default tableColsPayment;
